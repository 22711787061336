<template>
  <div id="tree">
    <transition name="tree">
      <div class="tree-scroll" v-if="affixDrawer">
        <div class="tree-header">企业列表</div>
        <div class="search">
          <a-button
            v-if="TreeLX == 5"
            @click="handleChange"
            type="primary"
            style="
              line-height: 30px;
              margin-right: 12px;
              width: 68px;
              height: 32px;
              font-size: 16px;
              background-color: #1876ff;
            "
          >
            全国
          </a-button>
          <base-select
            style="margin-right: 12px"
            v-if="TreeLX == 6"
            @baseChange="onChange"
          ></base-select>
          <a-input-search style="flex: 1" v-model="MC" @search="onSearch" />
        </div>
        <div class="tree-shell">
          <a-tree
            ref="tree"
            blockNode
            :checkable="checkable"
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :selected-keys="selectedKeys"
            :tree-data="treeData"
            showLine
            show-icon
            @expand="onExpand"
            @select="onSelect"
            @load="onLoad"
          >
            <a-icon style="color: #1876ff" slot="folder" type="folder-o" />
          </a-tree>
        </div>
      </div>
    </transition>

    <a-affix :style="{ position: 'absolute', top: '50%', right: '-12px' }">
      <div @click="showDrawer" class="toggle-button">
        <a-icon :type="iconType" />
      </div>
    </a-affix>
  </div>
</template>

<script>
import { http } from "../utils/Http.js";

import { getHttp } from "../utils/GetHttp.js";

import BaseSelect from "./BaseSelect.vue";

export default {
  name: "Tree",
  props: {
    TYPE: String,
  },

  components: {
    BaseSelect,
  },

  data() {
    return {
      expandedKeys: null, //展开指定的树节点
      autoExpandParent: true, //是否自动展开父节点
      selectedKeys: null, //设置选中的树节点
      checkable: false, //节点前添加 Checkbox 复选框
      // treeData,
      treeData: [],
      switcherIcon: "carry-out",
      MC: "",
      TreeLX: 6, //当前页面的类型 树节点叶子类型
      fromTreeLX: null, //上一个页面的类型
      treeType: null, //从基站数据异常列表进入报警信息的标准

      LX: 0, //基站类型  默认为全部

      affixDrawer: true,
      iconType: "left",
    };
  },

  watch: {
    selectedKeys(val) {
      // console.log('onCheck', val);
    },

    $route(to, from) {
      console.log(to);
      console.log(from);
    },
    //  $route(to, from) {
    // 	this.$store.commit('setTreeLX', from.meta.TreeLX); //展开指定的树节点
    //     },
  },

  created() {
    this.treeType = this.TYPE;

    // let me = this;

    // if( me.$store.state.expandedKeys){
    // console.log('aaaaaa');

    //   me.expandedKeys = me.$store.state.expandedKeys; //展开指定的树节点

    // }

    // if( me.$store.state.selectedKeys){
    // console.log('bbbbbb');

    //   me.selectedKeys = me.$store.state.selectedKeys; //设置选中的树节点

    // }

    // var me = this;
    // me.TreeLX = this.$route.meta.TreeLX;
    // me.fromTreeLX = this.$store.state.treeLX;
    // console.log(me.TreeLX);
    // console.log(me.fromTreeLX);
    // var fullPath = this.$route.fullPath;
    // switch (fullPath) {
    // 	case "/main/home":
    // 		me.TreeLX = 5
    // 		break;
    // 	case "/main/real":
    // 		me.TreeLX = 5
    // 		break;
    // 	case "/main/alarm":
    // 		me.TreeLX = 6
    // 		break;
    // 	case "/main/report":
    // 		me.TreeLX = 6
    // 		break;
    // 	case "/main/historical":
    // 		me.TreeLX = 6
    // 		break;
    // 	case "/main/exceed":
    // 		me.TreeLX = 5
    // 		break;
    // 	case "/main/basics":
    // 		me.TreeLX = 6
    // 		break;
    // 	default:
    // 		me.TreeLX = 6
    // }
    // this.handleChange();
  },

  mounted() {
    // 树结构数据
    // var me = this;
    // var i = 0;
    // var time = setInterval(function() {
    // 	me.expandedKeys = me.$store.state.expandedKeys; //展开指定的树节点
    // 	me.selectedKeys = me.$store.state.selectedKeys; //设置选中的树节点
    // 	me.treeData = me.$store.state.treeData;
    // 	i++;
    // 	if (i > 500) {
    // 		clearInterval(time);
    // 	}
    // 	if (me.expandedKeys && me.selectedKeys) {
    // 		clearInterval(time);

    // 		//选中的树节点数据对象
    // 		var selectData = me.$store.state.selectedData;
    // 		//选中的树节点父级数据对象
    // 		var parentData = me.$store.state.parentData;
    // 		me.$emit('select', selectData, parentData, 1);
    // 	}
    // }, 100)

    var me = this;

    me.TreeLX = this.$route.meta.TreeLX;

    me.fromTreeLX = this.$store.state.TreeLX;
    console.log(me.TreeLX);
    console.log(me.fromTreeLX);

    this.HJJC_TreeListGet();
  },

  methods: {
    selectNode(selectedKeys){
      this.selectedKeys=[selectedKeys];
      let treeOriginalData = this.$store.state.treeOriginalData;
      let selectData = null;
      for (var i = 0; i < treeOriginalData.length; i++) {
        if (treeOriginalData[i].id == selectedKeys) {
          this.expandedKeys=[treeOriginalData[i].parentId];
        }
      }
      this.onSelect([selectedKeys]);
    },
    onSearch(value) {
      // console.log(value);
      this.HJJC_TreeListGet(1);
    },

    //全国搜索
    handleChange() {
      this.MC = "";
      this.HJJC_TreeListGet(1);
    },

    onChange(item) {
      console.log(item);
      this.LX = item.FieldBH;
      this.HJJC_TreeListGet(1);
    },

    //展开/收起节点时触发
    onExpand(expandedKeys) {
      console.log(expandedKeys);
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },

    //点击树节点触发
    onSelect(selectedKeys, info) {
      // console.log(info);
      // console.log(info.node);
      console.log(selectedKeys);

      if (selectedKeys[0] == "0086") {
      }

      // console.log(info.node.title);
      this.selectedKeys = selectedKeys;
      var me = this;
      //树数据
      var treeOriginalData = this.$store.state.treeOriginalData;
      // console.log(treeOriginalData);

      let selectData = null;
      for (var i = 0; i < treeOriginalData.length; i++) {
        if (treeOriginalData[i].id == selectedKeys[0]) {
          me.$store.commit("setExpandedKeys", [treeOriginalData[i].parentId]); //展开指定的树节点
          me.$store.commit("setSelectedKeys", [treeOriginalData[i].id]); //设置选中的树节点
          me.$store.commit("setSelectedData", treeOriginalData[i]); //选中的树节点数据对象
          selectData = treeOriginalData[i];
          break;
        }
      }

      let parentData = treeOriginalData.filter(
        (it) => it.id == selectData.parentId
      );
      if (parentData && parentData.length > 0) {
        me.$store.commit("setParentData", parentData[0]); //展开指定的树节点数据对象
      }

      if (selectedKeys[0] == "0086") {
        me.$store.commit("setExpandedKeys", []); //展开指定的树节点
        me.$store.commit("setParentData", {}); //展开指定的树节点数据对象
      }

      me.$emit(
        "select",
        me.$store.state.selectedData,
        me.$store.state.parentData
      );

      // this.$emit('select', {
      // 	name: info.node.title,
      // 	value: selectedKeys[0]
      // })
    },

    //树节点加载完成触发
    onLoad(loadedKeys, info) {
      // console.log('aaa');
      // console.log(loadedKeys);
      // console.log(info);
    },

    HJJC_TreeListGet(TYPE) {
      var me = this;
      http({
        url: "/data/q30/HJJC_TreeListGet.aspx",
        data: {
          ckm: me.$store.state.loggedIn.CKM,
          MC: me.MC,
          LX: me.LX,
        },
        success(obj) {
          // console.log(obj);
          me.$store.commit("setTreeOriginalData", obj.Data);
          console.log(TYPE);

          me.autoExpandParent = true; //是否自动展开父节点

          if (TYPE == 1) {
            me.$store.commit("setExpandedKeys", null); //展开指定的树节点
            me.$store.commit("setSelectedKeys", null); //设置选中的树节点
            me.$store.commit("setSelectedData", {}); //选中的树节点数据对象
            me.$store.commit("setParentData", {}); //展开指定的树节点数据对象
          }

          if (obj.Data && obj.Data.length) {
            // TreeLX: 5  //公司为叶子节点
            // TreeLX: 6 	//基站为叶子节点
            // me.TreeLX 当前页面树的类型
            // me.fromTreeLX 当前一个页面树的类型

            if (me.$store.state.selectedKeys) {
              // if (me.TreeLX != me.fromTreeLX) {

              //从基站数据异常列表进入报警信息
              if (me.treeType == 1) {
                me.expandedKeys = me.$store.state.expandedKeys; //展开指定的树节点
                me.selectedKeys = me.$store.state.selectedKeys; //设置选中的树节点
              } else {
                //从基站进入公司
                if (me.TreeLX == 5) {
                  if (me.fromTreeLX == 5) {
                    me.expandedKeys = me.$store.state.expandedKeys; //展开指定的树节点
                    me.selectedKeys = me.$store.state.selectedKeys; //设置选中的树节点
                  } else if (me.fromTreeLX == 6) {
                    for (var i = 0; i < obj.Data.length; i++) {
                      if (me.$store.state.selectedData.Lx == 6) {
                        //如果点击的类型是6 上一个页面点击的是基站
                        if (
                          obj.Data[i].id ==
                          me.$store.state.selectedData.parentId
                        ) {
                          // console.log(obj.Data[i].id);

                          me.expandedKeys = [obj.Data[i].parentId]; //展开指定的树节点
                          me.selectedKeys = [obj.Data[i].id]; //设置选中的树节点

                          me.$store.commit("setExpandedKeys", [
                            obj.Data[i].parentId,
                          ]); //展开指定的树节点
                          me.$store.commit("setSelectedKeys", [obj.Data[i].id]); //设置选中的树节点
                          me.$store.commit("setSelectedData", obj.Data[i]); //选中的树节点数据对象
                          break;
                        }
                      } else {
                        if (obj.Data[i].id == me.$store.state.selectedData.id) {
                          // console.log(obj.Data[i].id);

                          me.expandedKeys = [obj.Data[i].parentId]; //展开指定的树节点
                          me.selectedKeys = [obj.Data[i].id]; //设置选中的树节点

                          me.$store.commit("setExpandedKeys", [
                            obj.Data[i].parentId,
                          ]); //展开指定的树节点
                          me.$store.commit("setSelectedKeys", [obj.Data[i].id]); //设置选中的树节点
                          me.$store.commit("setSelectedData", obj.Data[i]); //选中的树节点数据对象
                          break;
                        }
                      }
                    }
                  }
                } else if (me.TreeLX == 6) {
                  //从公司进入基站
                  if (me.fromTreeLX == 6) {
                    me.expandedKeys = me.$store.state.expandedKeys; //展开指定的树节点
                    me.selectedKeys = me.$store.state.selectedKeys; //设置选中的树节点
                  } else {
                    for (var i = 0; i < obj.Data.length; i++) {
                      if (
                        obj.Data[i].parentId == me.$store.state.selectedKeys[0]
                      ) {
                        console.log(obj.Data[i]);
                        me.expandedKeys = [obj.Data[i].parentId]; //展开指定的树节点
                        me.selectedKeys = [obj.Data[i].id]; //设置选中的树节点

                        me.$store.commit("setExpandedKeys", [
                          obj.Data[i].parentId,
                        ]); //展开指定的树节点
                        me.$store.commit("setSelectedKeys", [obj.Data[i].id]); //设置选中的树节点
                        me.$store.commit("setSelectedData", obj.Data[i]); //选中的树节点数据对象
                        break;
                      }
                    }
                  }
                }
              }
            } else {
              for (var i = 0; i < obj.Data.length; i++) {
                if (obj.Data[i].Lx == me.TreeLX) {
                  me.expandedKeys = [obj.Data[i].parentId]; //展开指定的树节点
                  me.selectedKeys = [obj.Data[i].id]; //设置选中的树节点
                  console.log(me.expandedKeys);
                  console.log(me.selectedKeys);
                  me.$store.commit("setExpandedKeys", [obj.Data[i].parentId]); //展开指定的树节点
                  me.$store.commit("setSelectedKeys", [obj.Data[i].id]); //设置选中的树节点
                  me.$store.commit("setSelectedData", obj.Data[i]); //选中的树节点数据对象

                  break;
                }
              }
            }

            //克隆数据
            let objTree = JSON.parse(JSON.stringify(obj));
            let parentData = objTree.Data.filter(
              (it) => it.id == me.$store.state.selectedData.parentId
            );
            //展开指定的树节点数据对象
            if (parentData && parentData.length > 0) {
              me.$store.commit("setParentData", parentData[0]); //展开指定的树节点数据对象
            }

            //公布事件
            me.$emit(
              "select",
              me.$store.state.selectedData,
              me.$store.state.parentData
            );

            // console.log(me.toTreeData(objTree.Data));
            //格式数据
            var Data = me.toTreeData(objTree.Data);
            console.log(Data);
            me.$store.commit("setTreeData", Data);
            me.treeData = Data;
          } else {
            me.expandedKeys = []; //展开指定的树节点
            me.selectedKeys = []; //设置选中的树节点
            me.treeData = [];
          }
        },
        failure(obj) {},
        me,
      });
    },

    //格式化树数据
    toTreeData(data) {
      var me = this;
      var pos = {};
      var tree = [];
      var i = 0;
      while (data.length != 0) {
        if (data[i].parentId == "0") {
          tree.push({
            key: data[i].id,
            title: data[i].name,
            slots: {
              treeData: data[i],
              icon: "folder",
            },
            children: [],
          });
          pos[data[i].id] = [tree.length - 1];
          data.splice(i, 1);
          i--;
        } else {
          var posArr = pos[data[i].parentId];
          if (posArr != undefined) {
            var obj = tree[posArr[0]];
            for (var j = 1; j < posArr.length; j++) {
              obj = obj.children[posArr[j]];
            }
            var isLeaf = false;
            var icons = "folder";
            if (data[i].Lx == me.TreeLX) {
              isLeaf = true;
              icons = "";
            }
            obj.children.push({
              key: data[i].id,
              title: data[i].name,
              isLeaf: isLeaf,
              slots: {
                treeData: data[i],
                icon: icons,
              },
              treeData: data[i],
              children: [],
            });
            pos[data[i].id] = posArr.concat([obj.children.length - 1]);
            data.splice(i, 1);
            i--;
          }
        }
        i++;
        if (i > data.length - 1) {
          i = 0;
        }
      }
      return tree;
    },

    showDrawer() {
      this.affixDrawer = !this.affixDrawer;
      if (this.iconType == "left") {
        this.iconType = "right";
      } else {
        this.iconType = "left";
      }
      //公开事件
      this.$emit("clickExpand", this.iconType);
    },
  },
};
</script>

<style scoped lang="scss">
#tree {
  height: 100%;
  background-color: #ffffff;
  border-radius: 15px 15px 0px 0px;
  position: relative;
  margin-right: 16px;

  .tree-scroll {
    height: 100%;
    width: 280px;
    border-radius: 15px 15px 0px 0px;
    overflow: hidden;

    .tree-header {
      height: 60px;
      line-height: 60px;
      background: url("../assets/img/pics/013.png") no-repeat center center;
      background-size: 100% 100%;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      border-radius: 15px 15px 0px 0px;
    }

    .search {
      padding: 20px 20px 10px;
      display: flex;
      align-items: center;
    }

    .tree-shell {
      // height: calc(100% - 200px);
      height: 615px;
      overflow: hidden;
      overflow-y: scroll;
      overflow-x: hidden;
      // padding: 0 0 0 15px;
      margin-right: 15px;

      &::v-deep .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
        color: #1876ff;
      }
    }

    .tree-shell::-webkit-scrollbar {
      width: 8px;
      background-color: #d9d9d9;
    }

    .tree-shell::-webkit-scrollbar-thumb {
      width: 8px;
      height: 10px;
      border-radius: 3px;
      background-color: #1876ff;
    }
  }

  .tree-enter-active,
  .tree-leave-active {
    transition: width 1s;
  }

  .tree-enter, .tree-leave-to /* .fade-leave-active below version 2.1.8 */ {
    width: 0;
  }

  .toggle-button {
    cursor: pointer;
    width: 12px;
    height: 60px;
    border-radius: 0 8px 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1876ff;

    .anticon {
      color: #ffffff;
    }
  }
}
</style>
